import React, { useRef, useEffect,useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Card, CardContent, Typography, Box,Button, CircularProgress,Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { validateOrder, resetOrder } from '../../../pages/payment/PaymentSlice';
import { sendEmailConfirmation, submitRegistration  } from '../../../pages/registration/registrationSlice'; 

const WorkshopPayment = () => {
  const scriptRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { registrationData, workshop } = location.state || {};
  const { orderId, error, validationError, isValid, loading, validationLoading ,isDialogOpen} = useSelector((state) => state.pay);
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleCloseDialog = () => {
    console.log('close clicked')
    setDialogOpen(false);
  };
  useEffect(() => {
    if (orderId) {
      loadRazorpayScript();
    }
  }, [orderId]);

  useEffect(() => {
    if (isValid) {
      const emailData = {
        recipientEmail: registrationData.email,
        name: registrationData.fullName,
        workshopTitle: registrationData.workshopTitle,
        paymentAmount: registrationData.discountedPrice,
        isWorkshop:true,
        paymentAmount:registrationData.paymentAmount

      };
      console.log(isValid,"is validated");
      setDialogOpen(isDialogOpen)
      dispatch(resetOrder());
      dispatch(submitRegistration(registrationData))
      dispatch(sendEmailConfirmation(emailData))
    }
  }, [isValid, navigate, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetOrder());
    };
  }, [dispatch]);

  const loadRazorpayScript = () => {
    if (scriptRef.current) {
      openRazorpay();
      return;
    }

    const script = document.createElement('script');
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    script.onload = () => {
      scriptRef.current = true;
      openRazorpay();
    };

    document.body.appendChild(script);
  };

  const openRazorpay = () => {
    if (orderId) {
      const options = {
        key: process.env.RZP_KEY_ID,
        amount: (workshop.discountedPrice * 100).toString(),
        currency: "INR",
        name: process.env.BUSINESS_NAME,
        description: `Payment for ${workshop.title}`,
        order_id: orderId,
        handler: function (response) {
          dispatch(validateOrder({
            order_id: response.razorpay_order_id,
            payment_id: response.razorpay_payment_id,
            signature: response.razorpay_signature,
            fullName: registrationData.fullName,
            email: registrationData.email,
            workshopId: workshop.id,
            isWorkshop: true
          }));
        },
        prefill: {
          name: registrationData.fullName,
          email: registrationData.email,
          contact: registrationData?.contact
        },
        theme: {
          color: "#3399cc"
        }
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.on('payment.failed', function (response) {
        alert(`Error: ${response.error.description}`);
      });

      rzp1.open();
    }
  };

  if (!workshop || !registrationData) {
    return <Typography variant="h6">Workshop or registration details not available.</Typography>;
  }
console.log(dialogOpen , "dialog open")
  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
       <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Payment Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your payment has been verified successfully! You are now registered for the workshop. You will get a confirmation email soon
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
      <Card>
        <CardContent>
          <Typography variant="h4" component="h1" gutterBottom>
            {workshop.title}
          </Typography>
          <Typography variant="body1" color="text.secondary" paragraph>
            {workshop.description}
          </Typography>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h5" color="text.primary">
              ₹{workshop.discountedPrice}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ textDecoration: 'line-through' }}>
              ₹{workshop.price}
            </Typography>
          </Box>
          {error && <Typography color="error">Error: {error}</Typography>}
          {validationError && <Typography color="error">{validationError}</Typography>}
        
          {loading || validationLoading ? <CircularProgress size={24} /> : null}
        </CardContent>
      </Card>
    </Container>
  );
};

export default WorkshopPayment;

