import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  CssBaseline,
  ThemeProvider,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomePage from './pages/home/Homepage';
import MyCoursePage from './components/course/MyCoursePage';
import AuthPage from './pages/auth/AuthPage';
import theme from './theme';
import { useSelector } from 'react-redux';
import ProtectedRoute from './ProtectedRoute';
import CoursePage from './components/course/CoursePage';
import Payment from './pages/payment/Payment';
import TermsAndConditions from './components/footer/TermsAndConditions';
import PrivacyPolicy from './components/footer/PrivacyPolicy';
import EnrollPage from './components/enroll/EnrollPage';
import Footer from './components/footer/Footer';
import AdminPage from './pages/admin/AdminPage';
import ScrollToTop from './components/footer/ScrollToTop';
import ForgotPassword from './pages/auth/ForgotPassword';
import PhoneAuth from './pages/auth/PhoneAuth';
import Workshop from './components/workshop/Workshop';
import WorkshopPayment from './components/workshop/workshopPayment/WorkshopPayment';



const App = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { isAuthenticated, userDetails } = useSelector((state) => state.auth);
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
      <ScrollToTop /> 
        <AppBar position="static">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              DSVCoding
            </Typography>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <Button color="inherit" component={Link} to="/">
                Home
              </Button>
              {isAuthenticated && (
                <Button color="inherit" component={Link} to="/mycourses">
                  My Courses
                </Button>
              )}
               {(isAuthenticated && userDetails?.email === 'testtest123@gmail.com') && (
                <Button color="inherit" component={Link} to="/admin">
                  Admin
                </Button>
              )}
              {!isAuthenticated && (
                <Button color="inherit" component={Link} to="/login">
                  Login
                </Button>
              )}
               <Button color="inherit" component={Link} to="/workshop">
                      Workshops
             </Button>
              {/* <Button color="inherit" component={Link} to="/terms">
                Terms and Conditions
              </Button>
              <Button color="inherit" component={Link} to="/privacy">
                Privacy Policy
              </Button> */}
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="temporary"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
          }}
        >
          <List>
            <ListItem button component={Link} to="/" onClick={handleDrawerToggle}>
              <ListItemText primary="Home" />
            </ListItem>
            {isAuthenticated && (
              <ListItem button component={Link} to="/mycourses" onClick={handleDrawerToggle}>
                <ListItemText primary="My Courses" />
              </ListItem>
            )}
            {!isAuthenticated && (
              <ListItem button component={Link} to="/login" onClick={handleDrawerToggle}>
                <ListItemText primary="Login" />
              </ListItem>
            )}
            {!isAuthenticated && (
              <ListItem button component={Link} to="/register" onClick={handleDrawerToggle}>
                <ListItemText primary="Register" />
              </ListItem>
            )}
               <ListItem button component={Link} to="/workshop" onClick={handleDrawerToggle}>
                <ListItemText primary="Workshops" />
                  </ListItem>
            {/* <ListItem button component={Link} to="/terms" onClick={handleDrawerToggle}>
              <ListItemText primary="Terms and Conditions" />
            </ListItem>
            <ListItem button component={Link} to="/privacy" onClick={handleDrawerToggle}>
              <ListItemText primary="Privacy Policy" />
            </ListItem> */}
         
          </List>
        </Drawer>
        <Box sx={{ p: 3 }}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/courses" element={<HomePage />} />
            <Route path="/courses/:courseId" element={<CoursePage />} />
            {/* <Route path="/login" element={isAuthenticated ? <Navigate to="/" /> : <AuthPage isLogin />} /> */}
            <Route path="/login" element={ <AuthPage isLogin />} />
            {/* <Route path="/register" element={isAuthenticated ? <Navigate to="/" /> : <AuthPage />} /> */}
            <Route path="/register" element={<AuthPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            {/* <Route path="/phone-auth" element={<PhoneAuth />} />  New Route */}
            <Route path="/admin" element={isAuthenticated && userDetails?.email === 'testtest123@gmail.com'? <AdminPage/> : <AuthPage />} />
            <Route
              path="/mycourses"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <MyCoursePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/payment"
              element={
                isAuthenticated ? <Payment /> : <Navigate to="/login" />
              }
            />
            
            <Route
              path="/workshop-payment"
              element={
             <WorkshopPayment /> 
              }
            />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route
              path="/enroll"
              element={
                isAuthenticated ? <EnrollPage /> : <Navigate to="/login" />
              }
            />
              <Route path="/workshop" element={<Workshop />} />
          </Routes>
        </Box>
      </Router>
      <Box sx={{ mt: '1000px' }}>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default App;
